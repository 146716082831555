import OtherExpenses from '../index'
import OtherExpensesForm from '../popup/edit'
import OtherExpensesRemove from '../popup/remove'

export default [
  {
    path: '/other-expenses',
    name: 'OtherExpenses',
    component: OtherExpenses,
    children: [
      {
        path: 'new',
        name: 'OtherExpenseNew',
        component: OtherExpensesForm
      },
      {
        path: ':id/edit',
        name: 'OtherExpenseEdit',
        component: OtherExpensesForm,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/remove',
        name: 'OtherExpenseRemove',
        component: OtherExpensesRemove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'otherExpenses',
      page: 'otherExpenses',
      tabPermissionKey: 'OtherExpenseMenu',
      category: 'Main_data'
    }
  }
]
