<template lang="pug">
  Popup
    template(slot="title")
      template(
        v-if="isEdit"
      ) {{ $t('OtherExpenses.form_EditTitle') }}
      template(
        v-else
      ) {{ $t('OtherExpenses.form_CreateTitle') }}

    template(slot="content")
      form.Form(
        id="form-other-expenses"
        @submit.prevent="onSubmitForm"
      )

        div.Form-item.required
          label.Form-item-label {{ $t('OtherExpenses.form_Field_Name') }}
          .control
            customTextInput(
              name="name"
              ref="firstField"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('OtherExpenses.form_Field_Name')"
              v-validate="validationRules"
              id="input-other-expenses-form-name"
              v-model="form.name"
              :isDelayInput="true"
            )
            Icon.success-check(
              v-show="validateName"
              id="icon-expense-popup-ok-name"
              name="icon-check"
              tabIndex="-1"
            )
            Button(
              id="btn-other-expense-popup-loading-name"
              variant="icon loading"
              tabindex="-1",
              v-show="isPendingName"
            )
              Loading(theme="disable")
            

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        .Form-item
          label.Form-item-label {{ $t('OtherExpenses.description') }}
          .control
            customTextareaInput.txt(
              id="input-other-expenses-description"
              name="description"
              :data-vv-as="$t('OtherExpenses.description')"
              v-validate="'max:128'"
              v-model="form.description"
              :error="veeErrors.has('description')"
            )
            showValidateError(
              v-show="veeErrors.has('description')"
              :errorName="veeErrors.first('description')"
            )

    template(slot="footer")
      div.add-other-items-checkbox-container(
        v-if="!isEdit"
      )
        CustomCheckbox(
        id="checkbox-expense-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('OtherExpenses.form_Name') })"
        v-model="saveAndNew")

      Button(
        primary
        id="btn-other-expenses-popup-submit"
        variant="full"
        size="medium"
        type="submit",
        form="form-other-expenses"
        :disabled="isLoading || expenseNameChanged"
        :isLoading="isLoading"
      )
        span(
          v-show="!isLoading"
        )
          template(
            v-if="isEdit"
          ) {{ $t('OtherExpenses.form_FinishEditButton') }}
          template(
            v-else
          ) {{ $t('OtherExpenses.form_FinishCreateButton') }}

</template>

<script>
import { mapFields } from 'vee-validate'
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'OtherExpenseForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        name: '',
        description: ''
      },
      item: {},
      saveAndNew: true
    }
  },

  async mounted () {
    if (this.isEdit) await this.init()
    await this.formFocus()
  },

  computed: {

    ...mapFields({
      flagsName: 'name',
      flagsDescription: 'description'
    }),

    validationRules () {
      const url = this.isEdit
        ? `OtherExpense/check/name?id=${this.$route.params.id},Name`
        : 'OtherExpense/check/name,Name'
      return `required|max:128|very_singularity:${url}`
    },

    isLoading () {
      return this.$wait.is(['createExpense', 'updateExpense', 'submitExpenseForm', 'getExpense'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    expenseNameChanged () {
      return this.item.name === this.form.name && this.item.description === this.form.description
    },

    isPendingName () {
      return this.flagsName.pending
    },

    validateName () {
      return this.flagsName.valid && !this.isPendingName
    },
    validateDescription() {
      return this.flagsDescription.valid
    }
  },

  methods: {
    ...mapActions('OtherExpenses', [
      'createExpense',
      'updateExpense',
      'checkExpenseName',
      'getExpense'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    async init () {
      await this.getExpense(this.$route.params.id).then(res => {
        this.item = res.data.otherExpense
        this.form.name = res.data.otherExpense.name
        this.form.description = res.data.otherExpense.description
      })
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    resetForm () {
      this.form = {
        name: ''
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async isValid => {
        if (!isValid) return
        if (this.isEdit) {
          const payload = {
            id: this.$route.params.id,
            name: this.form.name,
            description: this.form.description
          }
          await this.updateExpense(payload).then(res => {
            if (res) {
              const message = this.$t('OtherExpenses.notification_Updated')
              this.notifyShow({ message })
              this.close()
            }
            this.$emit('getList')
          })
        } else {
          const payload = {
            name: this.form.name,
            description: this.form.description
          }
          await this.createExpense(payload).then(res => {
            if (res) {
              const message = this.$t('OtherExpenses.notification_Created')
              this.notifyShow({ message })
              if (this.saveAndNew) {
                this.resetForm()
                this.formFocus()
                this.$nextTick(() => {
                  this.$validator.reset()
                })
              } else {
                this.close()
              }
              this.$emit('getList')
            }
          })
        }
      })
    }, 'submitExpenseForm')
  }
}
</script>

<style lang="scss" scoped>

  :deep() .icon-check {
    color: green !important
  }
  .success-check {
    position: absolute;
    right: 2%;
    top: 40%;
  }
</style>
