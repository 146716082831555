<template lang="pug">
  DeleteAlert(
    :item="item",
    :fields="fields",
    loading="OtherExpense/getExpense"
    :tableName="$t('OtherExpenses.remove_TableName')",
    @delegateOnAlertRemoveOk="itemRemove"
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'ExpenseRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('OtherExpenses.remove_TableColumn_Name_Short'),
        title: this.$t('OtherExpenses.remove_TableColumn_Name')
      }],
      item: {}
    }
  },

  computed: {
    isLoading () {
      return this.$wait.is(['expenseRemove'])
    }
  },
  async mounted () {
    this.getExpense(this.$route.params.id)
      .then(res => {
        this.item = res.data.otherExpense
      })
    
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('OtherExpenses', [
      'deleteExpense',
      'getExpense'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteExpense(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'expenseRemove')
  }
}
</script>
