export default function($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      headerTitle: true
    },
    {
      name: 'box-header-info',
      type: 'portal',
      get short_title() {
        return $t('ListsShelfLocation.description')
      },
      get title() {
        return $t('ListsShelfLocation.description')
      }
    }
  ]
}
