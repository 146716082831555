import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    OtherExpenseList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
  },

  getters: {
    OtherExpenseList: state => state.OtherExpenseList,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async getOtherExpenseList({ commit, state }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const loadingName = 'getOtherExpenseList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('OtherExpense/all', payload)
        },
        success: result => {
          commit('SET_OTHER_EXPENSE_LIST', result.data)
          return result
        }
      })
      return results
    },

    async createExpense({ commit, dispatch }, payload) {
      const loadingName = 'createOtherExpense'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('OtherExpense', payload)
        },
        success: result => result
      })
      return results
    },

    async updateExpense({ commit, dispatch }, payload) {
      const loadingName = 'updateOtherExpense'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('OtherExpense', payload)
        },
        success: result => result
      })
      return results
    },

    async deleteExpense({ commit, dispatch }, Id) {
      const loadingName = 'deleteExpense'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('OtherExpense', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async checkExpenseName({ commit, dispatch }, params) {
      const loadingName = 'checkExpenseName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('OtherExpense/check/name', { params })
        },
        success: result => result
      })
      return results
    },

    async getExpense({ commit }, id) {
      const loadingName = 'getOtherExpense'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('OtherExpense', { params: { id } })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    SET_OTHER_EXPENSE_LIST(state, data) {
      state.OtherExpenseList = data.otherExpenseList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    RESET_OTHER_EXPENSE_LIST(state, data) {
      state.OtherExpenseList = []
      state.Page.number = 1
      state.Page.size = 20
      state.Page.total = 0
    }
  }
}
