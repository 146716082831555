var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("OtherExpenses.form_EditTitle")))]
            : [_vm._v(_vm._s(_vm.$t("OtherExpenses.form_CreateTitle")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-other-expenses" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("OtherExpenses.form_Field_Name"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.validationRules,
                        expression: "validationRules",
                      },
                    ],
                    ref: "firstField",
                    attrs: {
                      name: "name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t("OtherExpenses.form_Field_Name"),
                      id: "input-other-expenses-form-name",
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c("Icon", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.validateName,
                        expression: "validateName",
                      },
                    ],
                    staticClass: "success-check",
                    attrs: {
                      id: "icon-expense-popup-ok-name",
                      name: "icon-check",
                      tabIndex: "-1",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        id: "btn-other-expense-popup-loading-name",
                        variant: "icon loading",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("OtherExpenses.description"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextareaInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:128",
                        expression: "'max:128'",
                      },
                    ],
                    staticClass: "txt",
                    attrs: {
                      id: "input-other-expenses-description",
                      name: "description",
                      "data-vv-as": _vm.$t("OtherExpenses.description"),
                      error: _vm.veeErrors.has("description"),
                    },
                    model: {
                      value: _vm.form.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "description", $$v)
                      },
                      expression: "form.description",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("description"),
                        expression: "veeErrors.has('description')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("description") },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-expense-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("OtherExpenses.form_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                primary: "",
                id: "btn-other-expenses-popup-submit",
                variant: "full",
                size: "medium",
                type: "submit",
                form: "form-other-expenses",
                disabled: _vm.isLoading || _vm.expenseNameChanged,
                isLoading: _vm.isLoading,
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [
                        _vm._v(
                          _vm._s(_vm.$t("OtherExpenses.form_FinishEditButton"))
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(
                            _vm.$t("OtherExpenses.form_FinishCreateButton")
                          )
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }